import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';
import {
  AccordionList,
  AccordionPalette,
  Button,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

interface AsyncConsultConfirmedProps {
  goBackToProfile: () => void;
  openChat: () => void;
  palette?: AccordionPalette;
}

export const AsyncConsultConfirmed: React.FC<AsyncConsultConfirmedProps> = ({
  goBackToProfile,
  openChat,
  palette,
}) => {
  const config = getConfig();
  const environment = useEnvironment();
  return (
    <div className="h-full space-y-6 text-center">
      <Typography size="xl" isBold>
        <FormattedMessage defaultMessage="Consult confirmed" />
      </Typography>
      <div>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="We’ll connect you with a pracitioner soon. You’ll receive an SMS when they start your text-based consult (usually within 48 hours)." />
        </Typography>
      </div>
      <div className="space-y-3">
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="In the meantime, if you have questions about your treatment you can send them in the chat for your practitioner to answer later." />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="For all other questions, contact our <a>customer support team</a>."
            values={{
              a: (chunks) => (
                <a
                  href={`${environment.landingPageUrl}${config.urlPaths.contact}`}
                  className="text-link"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
      </div>
      <div className="space-y-8">
        <div className="flex flex-col gap-4 justify-center md:flex-row">
          <Button
            level="primary"
            palette={getPrimaryButtonPalette(config.brand)}
            onClick={openChat}
          >
            <FormattedMessage defaultMessage="Open consult chat" />
          </Button>
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            onClick={goBackToProfile}
          >
            <FormattedMessage defaultMessage="Return to profile" />
          </Button>
        </div>
        <div className="text-left">
          <AccordionList
            palette={palette}
            items={[
              {
                title: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage defaultMessage="What to expect" />
                  </Typography>
                ),
                content: (
                  <div className="space-y-4">
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="We’ll send you an SMS when your practitioner starts your consult. All you’ll need to do is open the link provided in the text to respond." />
                    </Typography>
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Following your consult, if your practitioner has determined that treatment is appropriate for you, you will be asked to confirm your order." />
                    </Typography>
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Once confirmed, your order will be prepared and sent to your nominated shipping address." />
                    </Typography>
                  </div>
                ),
              },
              {
                title: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage defaultMessage="Frequently asked questions" />
                  </Typography>
                ),
                content: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage={`The team has written up answers to all the most common questions our patients ask. To read these, visit our <a>Frequently Asked Questions page.</a>`}
                      values={{
                        a: (chunks) => (
                          <a
                            href={`${environment.landingPageUrl}${config.urlPaths.faq}`}
                            className="text-link"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                      description="Async consult confirmed page"
                    />
                  </Typography>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
